import React, { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./sidebar.scss";

interface SidebarProps {
  showSideBar?: boolean;
  selectedTab?: string;
  setSelectedTab?: (tab: string) => void;
  stageClickHandler?: (stage: string) => void;
  buttons?: {
    id: string;
    label: string;
    iconClass: string;
    tooltipText: string;
    onClick: () => void;
    selectedTab: string;
    btnCustomClass?: string;
  }[];
  innerSidebarMenu?: {
    id: string;
    label: string;
  }[];
  parentClass?: string;
  SideSubmenuInside?: boolean;
  opportunityDetails?: any;
  stage?: string;
  pageMap?: string[];
  formWarnings?: string[];
  warningIconJsx?: JSX.Element;
}

const Sidebar = ({
  SideSubmenuInside = true,
  showSideBar = false,
  selectedTab = "home",
  setSelectedTab,
  buttons = [],
  innerSidebarMenu = [],
  parentClass = "",
  stageClickHandler = (stage) => {},
  opportunityDetails,
  stage,
  pageMap = [],
  formWarnings = [],
  warningIconJsx,
}: SidebarProps) => {
  const [showSubmenu, setShowSubmenu] = useState(SideSubmenuInside);
  const [showSidePanel, SetShowSidePanel] = useState(showSideBar);

  const toggleSideBar = () => {
    if (SideSubmenuInside) {
      setShowSubmenu(!showSubmenu);
      SetShowSidePanel(false);
    } else {
      SetShowSidePanel(!showSidePanel);
      setShowSubmenu(false);
    }
  };

  const changeTab = (tab: string) =>
    setSelectedTab ? setSelectedTab(tab) : "";

  const renderMenuButtons = () => {
  return buttons.map((button) => (
    <div key={button.id} className="menu-btn">
      <Button
        variant={selectedTab === button.selectedTab ? undefined  : "primary"} // Ensure proper variant
        className={button.btnCustomClass || "btn-link"}
        onClick={button.onClick}
      >
        {button.tooltipText ? (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id={button.id} className="mytooltip">
                {button.tooltipText}
              </Tooltip>
            }
          >
            <span className={button.iconClass}></span>
          </OverlayTrigger>
        ) : (
          <span className={button.iconClass}></span>
        )}
      </Button>
    </div>
  ));
};

  const renderSubmenuButtons = () => {
    return buttons.map((button) => (
      <Button
        key={button.id}
        variant={selectedTab === button.selectedTab ? "select" : ""}
        className={button.btnCustomClass || ""}
        onClick={() => changeTab(button.selectedTab)}
      >
        <span className={button.iconClass}></span>
        <span>{button.label}</span>
      </Button>
    ));
  };

  const renderSidebarStages = () => {
    return (
      <div className="sidebaropportmenu">
        <div className="opportunity-tab">
          <span className="icon icon-business_center"></span>
          Opportunity -
        </div>
        <div className="sidebar">
          {innerSidebarMenu.map((innermenuItem) => (
            <div
              key={innermenuItem.id}
              className={`opportunity-stage`}
              onClick={() => stageClickHandler(innermenuItem.id)}
            >
              {innermenuItem.label}
              {formWarnings?.includes(innermenuItem.label) && warningIconJsx}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={`${parentClass}`}>
      <Button
        className={`burgerMenu-btn ${
          showSidePanel || showSubmenu ? "burgerMenu-open" : ""
        }`}
        onClick={toggleSideBar}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </Button>

      <div
        className={`page-content-inner ${
          showSidePanel || showSubmenu ? "open" : "close"
        }`}
      >
        <div className={`side-menu-bar shadow-element`}>
          {renderMenuButtons()}
        </div>
        {showSubmenu && SideSubmenuInside ? renderSidebarStages() : null}
        {showSidePanel && !SideSubmenuInside ? (
          <div className={`dashboard-sidebar`}>{renderSubmenuButtons()}</div>
        ) : null}
      </div>
    </div>
  );
};

export default Sidebar;
