import { useFormikContext } from 'formik';
import React from 'react';

import { Form } from 'react-bootstrap';
import './quantity-input.scss';

interface Props {
  name: string;
  label?: string;
  isDisabled?: boolean;
  type?: string;
  placeHolder: string;
  formGroupClassName?: string;
  formControlClassName?: string;
  value?: string;
  error?: string | undefined | boolean;
  handleChange?: (value: any) => void;
  handleBlur?: (value: any) => void;
  showInfo?: string;
  minimum?: number;
  disable?: { field: string; value: string; }
  isAmount?: any
}

const QuantityInput = (props: Props) => {
  const {
    name,
    label = '',
    type = 'number',
    placeHolder,
    formGroupClassName = '',
    formControlClassName = '',
    value,
    error,
     isAmount= '',
    handleChange = () => {},
    handleBlur,
    isDisabled,
    minimum = 0,
    disable
  } = props;

  const { values, errors, setFieldValue, touched }: any = useFormikContext();

  const checkDisabled = () => {
    if (Array.isArray(disable)) {
      return disable?.length && disable.find((condition: any) =>
        values[condition.field]?.toLowerCase() === condition.value.toLowerCase()
      );
    } else return disable && values[disable.field] === disable.value;
  };

  const handleIncrement = () => {
    if (isDisabled || (disable && values[disable.field] === disable.value)) return;
    const currentValue = parseFloat(values[name]) || 0;
    setFieldValue(name, currentValue + 1);
    handleChange(currentValue + 1);
  };

  const handleDecrement = () => {
    if (isDisabled) return;
    const currentValue = parseFloat(values[name]) || 0;
    if (currentValue > minimum) {
      setFieldValue(name, currentValue - 1);
      handleChange(currentValue - 1);
    }
  };

  return (
    <div className={`quantity_input`}>
      <Form.Group className={formGroupClassName}>
        {label && <Form.Label>{label}</Form.Label>}
        <div className='d-flex quantityWrapper'>
          <Form.Control
            disabled={isDisabled || checkDisabled()}
            className={formControlClassName}
            name={name}
            type={type}
            value={values[name] || ''}
            placeholder={placeHolder || 'Enter Quantity'}
            onChange={(e) => {
              const newValue = parseFloat(e.target.value);
              if (!isNaN(newValue)) {
                setFieldValue(name, newValue);
                handleChange(newValue);
              }
            }}
            onBlur={handleBlur}
            onWheel={(e) => e.currentTarget.blur()}
          />
          <div className='arrowContainer'>
            <span
              className='arrowWrapper'
              onClick={handleIncrement}
            >
              <img
                src='/images/increment-icon.svg'
                alt='increment icon'
                height={15}
                width={15}
              />
            </span>
            <span
              className='arrowWrapper'
              onClick={handleDecrement}
            >
              <img
                src='/images/decrement-icon.svg'
                alt='decrement icon'
                height={15}
                width={15}
              />
            </span>
          </div>
        </div>

        {touched[name] && errors[name] && (
          <small className='text-danger'>{errors[name]}</small>
        )}
      </Form.Group>
    </div>
  );
};

export default QuantityInput;
