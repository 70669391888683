// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.retex_table table {
  border: 1px solid #4f84e0;
}
.retex_table thead th {
  background-color: #4f84e0;
  color: #fff;
}
.retex_table th,
.retex_table tr {
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  margin: auto;
  width: 161px;
  height: 60px;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/components/retex-table/retex-table.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;AAAJ;AAEE;EACE,yBAAA;EACA,WAAA;AAAJ;AAEE;;EAEE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;AAAJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
