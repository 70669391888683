import React, { useEffect, useState } from 'react';
import { Bars } from 'react-loader-spinner';

interface LoaderProps {
  styles?: React.CSSProperties;
  wrapperClass?: string;
  color?: string;
  visible?: boolean;
  height?: string | number;
  width?: string | number;
  ariaLabel?: string;
  loaderType?: 'bars' | 'otherLoaderType'; // Extend with other loader types if needed
  autoHideDelay?: number; // Time in milliseconds to auto-hide the loader
}

const Loader: React.FC<LoaderProps> = ({
  styles = { display: 'flex' },
  wrapperClass = 'loadingOverlay',
  color = '#b7c9e7',
  visible = false,
  height = '80',
  width = '80',
  ariaLabel = 'loading',
  loaderType = 'bars',
  autoHideDelay = 3000, // Default delay of 3 seconds
}) => {
  const [isVisible, setIsVisible] = useState(visible);

  useEffect(() => {
    setIsVisible(visible);
    if (visible && autoHideDelay > 0) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, autoHideDelay);
      return () => clearTimeout(timer);
    }
  }, [visible, autoHideDelay]);

  // Placeholder for other loader types in the future
  const LoaderComponent = loaderType === 'bars' ? Bars : Bars;

  return (
    <div style={{ ...styles, display: isVisible ? 'flex' : 'none' }}>
      <LoaderComponent
        height={height}
        width={width}
        color={color}
        ariaLabel={ariaLabel}
        wrapperClass={wrapperClass}
        visible={isVisible}
      />
    </div>
  );
};

export default Loader;
