import React from 'react';
import { Button } from 'react-bootstrap';
import Loader from '../loader';
import './file-uploader.scss';

interface Props {
  id: string;
  filePath?: string;
  file?: File;
  hideFileName?: boolean;
  labelBeforeImageSelection: string;
  labelAfterImageSelected: string;
  imgWidth: string | number;
  imgHeight: string | number;
  defaultImageURL?: string;
  defaultVideoFile?: string;
  defaultDocFile?: string;
  docName?: string;
  fileDataObj?: any;
  removeOuterPadding?: boolean;
  extraTextBelowLabel?: string;
  isLandsacpeImage?: boolean;
  onFileChange: (file: File | FileList) => void;
  onFileRemove: () => void;
  onUpload: (file: File) => void;
  mediaType?: 'image' | 'video' | 'document' | 'docSheet';
  cropSelectionWidth?: number;
  cropSelectionHeight?: number;
  aspectRatio?: number;
  hideRemoveButton?: boolean;
  hideDownloadButton?: boolean;
  isEditable?: boolean;
  isMultiple?: boolean;
  isDisabled?: boolean;
  isUploading?: boolean;
  fieldError?: string;
  fieldTouched?: boolean;
}

export default class FileUploader extends React.Component<Props> {
  acceptedFormat = {
    image: '.png, .jpg, .jpeg',
    video: 'video/*',
    document: '.pdf',
    docSheet: '.pdf, .csv, .xls, .xlsx',
  }[this.props.mediaType || 'image'];

  fileRef: React.RefObject<HTMLInputElement> = React.createRef();
  width = this.props.isLandsacpeImage ? 200 : 100;

  state = {
    mediaPath: this.props.filePath || '',
    docName: this.props.docName || '',
    isUploadButtonDisabled: true,
    isRemoveButtonDisabled: !this.props.filePath,
    file: null,
    files: [] as File[],
    fileDataObj: this.props.fileDataObj || {},
    isLoaderActive: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.filePath !== this.props.filePath) {
      this.setState({
        mediaPath: this.props.filePath || '',
        fileDataObj: this.props.fileDataObj || {},
        docName: '',
        isRemoveButtonDisabled: !this.props.filePath,
      });
    }

    if (this.props.defaultDocFile && !this.state.docName) {
      this.setState({ docName: this.props.defaultDocFile });
    }
  }

  handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      this.setState({
        mediaPath: URL.createObjectURL(file),
        isUploadButtonDisabled: false,
        isRemoveButtonDisabled: false,
        file,
        files: Array.from(files),
        docName: file.name,
      });
      this.props.onFileChange(this.props.isMultiple ? files : file);
    }
  };

  handleRemove = () => {
    this.setState({
      mediaPath: '',
      fileDataObj: {},
      docName: '',
      isUploadButtonDisabled: true,
      isRemoveButtonDisabled: true,
    });
    this.props.onFileRemove();
  };

  render() {
    const {
      labelBeforeImageSelection,
      labelAfterImageSelected,
      extraTextBelowLabel,
      imgWidth,
      imgHeight,
      defaultImageURL,
      removeOuterPadding,
      isLandsacpeImage,
      id,
      hideRemoveButton,
      isEditable,
      isDisabled,
      isUploading,
      fieldTouched,
      fieldError,
    } = this.props;

    const { mediaPath, docName, isRemoveButtonDisabled } = this.state;

    return (
      <>
        <Loader visible={this.state.isLoaderActive} />
        <div
          className={`file_upload_wrapper ${
    isDisabled ? 'disableInput' : ''
  } justify-content-center uploader-wrapper`}
        >
          <div
            className={`text-center ${removeOuterPadding ? '' : 'p-4'} ${
              this.props.mediaType === 'video' ? 'video-upload' : ''
            }`}
          >
            {(mediaPath || defaultImageURL || docName) && (
              this.props.mediaType === 'image' && (
                <img
                  src={mediaPath || defaultImageURL}
                  alt=''
                  width={imgWidth}
                  height={imgHeight}
                  className='placeholder-img img-object-fit-contain'
                />
              )
            )}

            <div
              className={`placeholderText`}
              onClick={() => {
                if (isEditable || !mediaPath) {
                  this.fileRef.current?.click();
                }
              }}
            >
              <div>+</div>
              <small className='text-center cursor'>
                {mediaPath ? labelAfterImageSelected : labelBeforeImageSelection}
                <br />
                {extraTextBelowLabel}
              </small>
            </div>
          </div>

          <div className='d-none'>
            <input
              ref={this.fileRef}
              type='file'
              accept={this.acceptedFormat}
              name='fileUpload'
              id={`fileUpload-${id}`}
              multiple={this.props.isMultiple}
              disabled={isDisabled}
              onClick={(e) => (e.target as HTMLInputElement).value = ''}
              onChange={this.handleFileChange}
            />
          </div>

          <div className='mt-3'>
            {!hideRemoveButton && mediaPath && (
              <Button
                variant='outline-danger'
                className='float-right btn-remove'
                disabled={isRemoveButtonDisabled}
                onClick={this.handleRemove}
              >
                <img
                  src='/images/delete.svg'
                  alt='remove file icon'
                  height={15}
                  width={15}
                />
              </Button>
            )}
          </div>
        </div>

        {(this.props.mediaType === 'document' || this.props.mediaType === 'docSheet' || this.props.mediaType === 'video') &&
          docName &&
          !this.props.hideFileName && (
            <div className={`document_name doc-name d-flex align-items-center`}>
              <img
                src='/images/document-icon.svg'
                alt='document icon'
                width={24}
                height={24}
              />
              <span>{docName}</span>
            </div>
          )}

        {fieldTouched && fieldError && <small className='text-danger'>{fieldError}</small>}
        {isUploading && <small className='text-danger'>File upload is in progress</small>}
      </>
    );
  }
}
