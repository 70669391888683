import * as Yup from "yup";
import { getIn } from "formik";
// import Router from 'next/router';
// import { ServerResponse } from 'http';
// import { Departments, Messages } from '../VO/app.constants';
import moment from "moment";
import { toast } from "react-toastify";
// import { componentsData } from '../pages/opportunity/[opportunity_id]/rfq/rfq-form-model';
// import { nominationComponentsData } from '../pages/opportunity/[opportunity_id]/nomination/nomination-form-model';
// import { bgaComponentsData } from '../pages/opportunity/[opportunity_id]/bga/bga-form-model';
// import { pg1ComponentsData } from '../pages/opportunity/[opportunity_id]/pg1/pg1-form-model';
// import _ from 'lodash';
// import { CreateNew } from './enum.util';
// import { FileService } from '../service/files.service';

export const youtubeRegex =
  /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$/;

export const isEmptyObj = (obj: any): boolean => {
  return !!obj && Object.keys(obj).length === 0;
};
export const getDownloadMediaUrl = (filePath: string) => {
  return filePath.includes("https") || filePath.includes("http")
    ? filePath
    : `${process.env.NEXT_PUBLIC_API_URL}/file/download/${filePath}`;
};
export const getTimeTag = (dateStr: string) => {
  var startDate = moment(dateStr).format("DD.MM.YYYY HH:mm:ss");
  var endDate = moment().format("DD.MM.YYYY HH:mm:ss");
  const endFomat = moment(endDate, "DD.MM.YYYY HH:mm:ss");
  const startFormat = moment(startDate, "DD.MM.YYYY HH:mm:ss");
  const duration = moment.duration(endFomat.diff(startFormat));
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes());
  const seconds = Math.floor(duration.asSeconds());
  const years = Math.floor(duration.asYears());
  const months = Math.floor(duration.asMonths());
  const days = Math.floor(duration.asDays());
  const tag = years
    ? `${years}Y`
    : months
    ? `${months}M`
    : days
    ? `${days}d`
    : hours
    ? `${hours}h`
    : minutes
    ? `${minutes}m`
    : seconds
    ? `${seconds}s`
    : "0s";
  return tag;
};
export const camelCase = (string: string) => {
  if (!string) return "";
  const capitalizedString = string
    .toLowerCase()
    .split(/[\s_]+/)
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");
  return capitalizedString;
};

export const capitalCase = (str: string) => {
  let splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

export function downloadFile(url: any, fileName?: string) {
  const downloadElement = document.createElement("a");
  downloadElement.href = url;
  downloadElement.download = fileName ?? url.split("/").pop();
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
}

// export const downloadDocument = async (
//   file: any,
//   setLoaderActive = (isLoading: boolean) => {}
// ) => {
//   const fileService = new FileService();
//   const { uri, mimeType, originalFileName } = file;
//   if (uri) {
//     setLoaderActive(true);
//     const isLocalBlob = uri.includes('blob:http');
//     const fileResp = await fileService.getFileUrl(uri, isLocalBlob);
//     if (fileResp.data) {
//       const blob = new Blob([fileResp.data], {
//         type: mimeType,
//       });
//       const data = window.URL.createObjectURL(blob);
//       downloadFile(data, originalFileName);
//     }
//     setLoaderActive(false);
//   }
// };

// export const getMediaUrl = (filePath: string) => {
//   return filePath.includes('https') || filePath.includes('http')
//     ? filePath
//     : `${process.env.NEXT_PUBLIC_API_URL}/file/download/${filePath}`;
// };

// export const getImage = (image: any, defaultImage: string) => {
//   if (image && image !== null) {
//     if (typeof image === 'object') return URL?.createObjectURL(image);
//     else return getDownloadMediaUrl(image);
//   }
//   return defaultImage;
// };
// export const breakPointImageType = () => {
//   return process.browser && window
//     ? window.innerWidth <= 576
//       ? 'mobile'
//       : window.innerWidth < 768
//       ? 'portrait'
//       : 'landscape'
//     : null;
// };

// export const getFormattedOptionsData = (
//   data: any,
//   labelKey = 'name',
//   valueKey = 'id'
// ) => {
//   return data.map((item: any) => {
//     return {
//       value: item[valueKey],
//       label: capitalCase(item[labelKey]),
//       color:
//         item.is_active != undefined
//           ? item.is_active == 1
//             ? ''
//             : '#ff5665'
//           : '',
//     };
//   });
// };

// export const getFormatedDropdowns = (optionsData: any[]) => {
//   return (
//     optionsData?.map((item) => {
//       const titleCaseItem = item;
//       return typeof titleCaseItem == 'string'
//         ? { label: titleCaseItem, value: titleCaseItem }
//         : {
//             label: `${titleCaseItem.name}`,
//             value: titleCaseItem.email,
//           };
//     }) || []
//   );
// };

// export const formattedHours = (hourStr: string) => {
//   const [hrs, minutes] = hourStr.split(':');
//   let timeType;
//   if (Number(hrs) > 11 && Number(hrs) != 24) {
//     timeType = 'PM';
//   } else {
//     timeType = 'AM';
//   }
//   return `${Number(hrs) % 12}:${minutes} ${timeType}`;
// };

// export const isString = (myVar: any) => {
//   return typeof myVar === 'string' || myVar instanceof String;
// };

// export const getValidURl = (url: string) => {
//   const pattern = /^((http|https|ftp):\/\/)/;

//   if (url.trim() != '' && !pattern.test(url)) {
//     url = 'http://' + url;
//   }
//   return url;
// };

// export const getQueryParams = (query: any): string => {
//   return Object.keys(query)
//     .map((key) => key + '=' + query[key])
//     .join('&');
// };

// export const formatToTwoDigit = (val: number): string => {
//   if (val === 0) {
//     return '0';
//   }
//   return val < 10 ? `0${val}` : val.toString();
// };

// export function stripHtml(html: string): string {
//   const _stripTag = html.replace(/<[^>]*>?/gm, '');
//   return _stripTag.replace(/&nbsp;/gm, ' ');
// }

// export function getCurrentMonth(): number {
//   const d = new Date();
//   return d.getMonth() + 1;
// }
// export function getCurrentYear(): number {
//   const d = new Date();
//   return d.getFullYear();
// }

// export function getFirstDayOfCurrentMonth(): string {
//   return `${getCurrentMonth()}/01/${getCurrentYear()}`;
// }

// export function getFirstDayOfCurrentYear(): string {
//   return `01/01/${getCurrentYear()}`;
// }

// export function getRandomString(): string {
//   return Math.random()
//     .toString(36)
//     .replace(/[^a-z]+/g, '')
//     .substr(0, 5);
// }

// export function getRole(role: string) {
//   if (role && role.indexOf(',') !== -1) {
//     const roles = role.split(',');
//     return roles[1];
//   }
//   return role;
// }

// export function onlyUnique(value: any, index: number, array: any[]) {
//   return array.indexOf(value) === index;
// }

// export function isMobileDevice() {
//   if (
//     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//       navigator.userAgent
//     )
//   ) {
//     return true;
//   } else {
//     return false;
//   }
// }

// export function isSafari() {
//   //var is_safari = navigator.userAgent.toLowerCase().indexOf("safari/") > -1;
//   return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
// }

export const getErrorMessage = (message: any) => {
  let resultMessage;
  if (Array.isArray(message)) {
    resultMessage = message.map((message: string) => message);
  } else resultMessage = message;
  return resultMessage;
};

export const showError = (message: string): void => {
  toast.error(message, {
    hideProgressBar: true,
    position: "bottom-right",
    autoClose: 2000,
  });
};
export const showSuccess = (message: string): void => {
  toast.success(message, {
    hideProgressBar: true,
    position: "bottom-right",
    autoClose: 2000,
  });
};
// export const showWarn = (message: string): void => {
//   toast.warn(message, {
//     hideProgressBar: true,
//     position: 'bottom-right',
//     autoClose: 2000,
//   });
// };
// export const changeModelDataHelper = (
//   fieldName: string,
//   propToAdd: any,
//   modelData: any,
//   setNewModelData: any
// ) => {
//   console.log('comes here ====>', fieldName, propToAdd);
//   let modifiedData: any = modelData;
//   if (modelData?.length) {
//     modifiedData = [
//       ...modelData.map((obj: any) => {
//         const { title, fieldSetArr, wrapperSetArr } = obj;
//         if (fieldSetArr?.length) {
//           obj.fieldSetArr = fieldSetArr.map((val: any) => {
//             if (fieldName === val.name) {
//               return { ...val, ...propToAdd };
//             } else {
//               return val;
//             }
//           });
//         }
//         if (wrapperSetArr?.length) {
//           obj.wrapperSetArr = wrapperSetArr.map((set: any) => {
//             const { fieldSetArr } = set;
//             if (fieldSetArr?.length) {
//               set.fieldSetArr = fieldSetArr.map((val: any) => {
//                 if (fieldName === val.name) {
//                   return { ...val, ...propToAdd };
//                 } else {
//                   return val;
//                 }
//               });
//             }
//             return set;
//           });
//         }
//         return obj;
//       }),
//     ];
//   }
//   setNewModelData(modifiedData);
// };
// export const getStructuredData = (
//   structure: any,
//   data: any,
//   dropdownData?: any
// ) => {
//   const structuredData: any = [];
//   if (structure?.length) {
//     structure.map((obj: any) => {
//       const { title, fieldSetArr, wrapperSetArr } = obj;
//       const structureObj: any = { rowArr: [] };
//       structureObj.title = title;
//       fieldSetArr?.length &&
//         fieldSetArr.map((val: any) => {
//           let value = data[val.name];
//           if (dropdownData) {
//             let selectedOpt = dropdownData[val.name]
//               ?.filter((opt: any) => {
//                 return Array.isArray(data[val.name])
//                   ? data[val.name].includes(opt.value)
//                   : opt.value == data[val.name];
//               })
//               ?.map((data: any) => data.label);

//             if (selectedOpt?.length) value = selectedOpt.join(', ');
//           }
//           let valueOptional = null;
//           if (val.optionalComponent) {
//             const { name } = val.optionalComponent;
//             if (data[name]) {
//               valueOptional = data[name];
//             }
//           }
//           if (value || valueOptional) {
//             structureObj.rowArr.push({
//               rowKey: val.name,
//               rowName: val.label,
//               rowVal: value,
//               rowOptionalVal: valueOptional,
//             });
//           }
//         });
//       wrapperSetArr?.length &&
//         wrapperSetArr.map((set: any) => {
//           const { fieldSetArr } = set;
//           fieldSetArr?.length &&
//             fieldSetArr.map((val: any) => {
//               let value = data[val.name];
//               if (dropdownData) {
//                 let selectedOpt = dropdownData[val.name]
//                   ?.filter((opt: any) => {
//                     return Array.isArray(data[val.name])
//                       ? data[val.name].includes(opt.value)
//                       : opt.value == data[val.name];
//                   })
//                   ?.map((data: any) => data.label);

//                 if (selectedOpt?.length) value = selectedOpt.join(', ');
//               }
//               if (value) {
//                 structureObj.rowArr.push({
//                   rowKey: val.name,
//                   rowName: val.label,
//                   rowVal: value,
//                 });
//               }
//             });
//         });
//       if (structureObj.rowArr?.length) structuredData.push(structureObj);
//     });
//     return structuredData;
//   }
// };

// export const getFileTypeFields = (structure: any, data: any) => {
//   const structuredData: any = {};
//   if (structure.length) {
//     structure.map((obj: any) => {
//       const { fieldSetArr } = obj;
//       fieldSetArr?.length &&
//         fieldSetArr.map((val: any) => {
//           const value = data[val.name];
//           if (val.type == 'filepicker') {
//             structuredData[val.name] = value;
//           }
//           if (val.optionalComponent) {
//             const { name } = val.optionalComponent;
//             const value = data[name];
//             if (val.type == 'filepicker') {
//               structuredData[val.optionalComponent.name] = value;
//             }
//           }
//         });
//     });
//     return structuredData;
//   }
// };

export function generatePaginationArray(
  currentPage: any,
  totalItems: any,
  itemsPerPage: any,
  displayPages: any
) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pages = [];
  if (totalPages >= 2) {
    pages.push("<");
  }
  if (totalPages <= displayPages) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    const halfDisplay = Math.floor(displayPages / 2);
    let startPage = currentPage - halfDisplay;
    let endPage = currentPage + halfDisplay;

    if (startPage < 1) {
      startPage = 1;
      endPage = displayPages;
    } else if (endPage > totalPages) {
      endPage = totalPages;
      startPage = totalPages - displayPages + 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Add ellipsis if last page is not the last item
    if (Number(pages[pages.length - 1]) < totalPages) {
      pages.push("...");
    }
  }

  // Add the last page
  if (pages[pages.length - 1] !== totalPages) {
    pages.push(totalPages);
  }

  if (totalPages >= 2) {
    pages.push(">");
  }

  return pages;
}

// export const concatDropdownData = (
//   objToConcat: any,
//   dropdownData: any,
//   setDropdowns: any
// ) => {
//   setDropdowns({ ...(dropdownData || {}), ...objToConcat });
// };

// export const getHistoryJSX = (historyData: any, dropdownData?: any) => {
//   const structureByStage = [
//     { stage: 'rfq', component: componentsData },
//     { stage: 'pg1', component: pg1ComponentsData },
//     { stage: 'bga', component: bgaComponentsData },
//     { stage: 'nomination', component: nominationComponentsData },
//   ];
//   const updateData = JSON.parse(historyData.change_content);
//   const { stage } = historyData;
//   let dataComponent = structureByStage.find(
//     (structure) => structure.stage == stage
//   );
//   //console.log('VG ::', stage, dataComponent?.component);
//   const dataObj = getStructuredData(
//     dataComponent?.component,
//     updateData,
//     dropdownData
//   );
//   return dataObj;
// };

// export const calculateRiskLevel = (score: string) => {
//   const scoreLevelMap = [
//     { min: 0, max: 5, level: 'low' },
//     { min: 5, max: 7, level: 'medium' },
//     { min: 7, max: 10, level: 'high' },
//   ];

//   const selectedObj = scoreLevelMap.find((scoreObj) => {
//     const { min, max } = scoreObj;
//     if (Number(score) > min && Number(score) <= max) {
//       return true;
//     } else return false;
//   });

//   return selectedObj?.level ? selectedObj?.level : '';
// };

// const getUserName = (object: any, user: any, isLabel?: boolean) => {
//   if (!user?.length) return null;

//   const selectedUser = object?.filter((data: any) =>
//     Array.isArray(user) ? user.includes(data.value) : data.value == user
//   );
//   if (selectedUser?.length) {
//     const formattedUsers = selectedUser
//       .map((data: any) => {
//         return isLabel ? data.label?.split(/\(|\)/)?.[0] : data.value;
//       })
//       ?.filter(
//         (data: any, index: any, array: any) => array.indexOf(data) == index
//       );
//     return formattedUsers;
//   } else {
//     return Array.isArray(user) ? user : [user];
//   }
// };

// export const getPersonsAssociatedWithOppId = (
//   opportunity: any,
//   dropdownData?: any,
//   isLabel?: boolean
// ) => {
//   const initiator = dropdownData
//     ? getUserName(
//         dropdownData['initiator'],
//         opportunity?.opportunity_created_by?.email,
//         isLabel
//       )
//     : [opportunity?.opportunity_created_by?.email];
//   const applicationEngg = dropdownData
//     ? getUserName(
//         dropdownData['skf_technical_contact'],
//         opportunity?.opportunity_rfq?.skf_technical_contact,
//         isLabel
//       )
//     : opportunity?.opportunity_rfq?.skf_technical_contact;
//   const sales = dropdownData
//     ? getUserName(
//         dropdownData['skf_sales_contact'],
//         opportunity?.opportunity_rfq?.skf_sales_contact,
//         isLabel
//       )
//     : opportunity?.opportunity_rfq?.skf_sales_contact;
//   const businessDev = dropdownData
//     ? getUserName(
//         dropdownData['business_development'],
//         opportunity?.opportunity_bga?.business_development,
//         isLabel
//       )
//     : opportunity?.opportunity_bga?.business_development;
//   const productEngg = dropdownData
//     ? getUserName(
//         dropdownData['product_engineering'],
//         opportunity?.opportunity_bga?.product_engineering,
//         isLabel
//       )
//     : opportunity?.opportunity_bga?.product_engineering;
//   const processEngg = dropdownData
//     ? getUserName(
//         dropdownData['process_engineering'],
//         opportunity?.opportunity_bga?.process_engineering,
//         isLabel
//       )
//     : opportunity?.opportunity_bga?.process_engineering;
//   const etoManager = dropdownData
//     ? getUserName(
//         dropdownData['eto_project_manager'],
//         opportunity?.opportunity_bga?.eto_project_manager,
//         isLabel
//       )
//     : opportunity?.opportunity_bga?.eto_project_manager;
//   const others = dropdownData
//     ? getUserName(
//         dropdownData['others'],
//         opportunity?.opportunity_bga?.others,
//         isLabel
//       )
//     : opportunity?.opportunity_bga?.others;
//   const data = {
//     applicationEngg,
//     sales,
//     businessDev,
//     productEngg,
//     processEngg,
//     etoManager,
//     others,
//     initiator,
//   };
//   return data;
// };

// export const getAccessByForm = (form: string, user: any, opportunity?: any) => {
//   if (process.env.NEXT_PUBLIC_WITHOUT_SSO == 'true') {
//     return {
//       initiate: true,
//       edit: true,
//       view: true,
//       activate: true,
//       revise: true,
//     };
//   }
//   let isAeroportSuperAdmin = false;
//   const { department } = user ?? { department: '' };
//   if (user?.user_data?.isAeroportSuperAdmin)
//     isAeroportSuperAdmin = user.user_data.isAeroportSuperAdmin;
//   const {
//     applicationEngg,
//     sales,
//     businessDev,
//     productEngg,
//     initiator,
//     etoManager,
//     others,
//     processEngg,
//   } = getPersonsAssociatedWithOppId(opportunity);
//   switch (form) {
//     case 'dashboard':
//       console.log(department);
//       if (
//         (department &&
//           [
//             Departments.Sales,
//             Departments.BusinessDevelopment,
//             Departments.ApplicationEngineering,
//           ].includes(department)) ||
//         isAeroportSuperAdmin
//       ) {
//         return { initiate: true, edit: true, view: true };
//       } else {
//         return { initiate: false, edit: false, view: true };
//       }
//     case 'rfq':
//       if (
//         (department &&
//           [
//             Departments.Sales,
//             Departments.BusinessDevelopment,
//             Departments.ApplicationEngineering,
//           ].includes(department)) ||
//         (initiator ?? []).includes(user.email) ||
//         isAeroportSuperAdmin
//       ) {
//         return { activate: true, edit: true, view: true };
//       } else {
//         return { activate: false, edit: false, view: true };
//       }
//     case 'bga':
//       if (
//         department === Departments.BusinessDevelopment ||
//         isAeroportSuperAdmin
//       ) {
//         return { activate: true, edit: true, view: true };
//       } else if (
//         (productEngg?.length && productEngg.includes(user.email)) ||
//         (others?.length && others.includes(user.email)) ||
//         (applicationEngg?.length && applicationEngg.includes(user.email)) ||
//         (sales?.length && sales.includes(user.email)) ||
//         (etoManager?.length && etoManager.includes(user.email)) ||
//         (processEngg?.length && processEngg.includes(user.email))
//       ) {
//         return { activate: false, edit: false, partial_edit:true, view: true };
//       } else {
//         return { activate: false, edit: false, view: true };
//       }
//     case 'pg1':
//       if (
//         department === Departments.BusinessDevelopment ||
//         isAeroportSuperAdmin
//       ) {
//         return { activate: true, edit: true, view: true };
//       } else if (
//         (productEngg?.length && productEngg.includes(user.email)) ||
//         (etoManager?.length && etoManager.includes(user.email)) ||
//         (others?.length && others.includes(user.email)) ||
//         (applicationEngg?.length && applicationEngg.includes(user.email)) ||
//         (sales?.length && sales.includes(user.email))
//       ) {
//         return { activate: false, edit: true, view: true };
//       } else {
//         return { activate: false, edit: false, view: true };
//       }
//     case 'nomination':
//       if (
//         (sales?.length && sales.includes(user.email)) ||
//         isAeroportSuperAdmin ||
//         department === Departments.BusinessDevelopment
//       ) {
//         return { activate: true, edit: true, view: true, revise: true };
//       } else if (
//         applicationEngg?.length &&
//         applicationEngg.includes(user.email)
//       ) {
//         return { activate: true, edit: true, view: true, revise: false };
//       } else {
//         return { activate: false, edit: false, view: true };
//       }
//   }
// };

export const isRequiredField = (
  validationSchema: Yup.ObjectSchema<any>,
  name: string,
  values: any
) => {
  let isRequired = null;
  if (validationSchema && !isEmptyObj(validationSchema)) {
    const schemaDescription = validationSchema.describe({ value: values });
    const accessor = name.split(".").join(".fields.");
    const field = getIn(schemaDescription.fields, accessor);
    // isRequired = field.tests.some((test: any) => test.name === 'required');
    return field ? !field.optional : false;
  }
  return isRequired;
};

// export const fixCustomerProgramPayload = (payload: any, type = 'draft') => {
//   const currentWarnings = payload['warnings'];
//   if (
//     payload.customer_group2?.toLowerCase() ===
//     CreateNew.createNewCustomer.toLowerCase()
//   ) {
//     if (!payload.new_customer) {
//       payload.customer_group2 = '';
//       if (currentWarnings) {
//         const index = currentWarnings.indexOf('customer');
//         index > -1 && currentWarnings.splice(index, 1),
//           (payload['warnings'] = [...currentWarnings]);
//       }
//     } else {
//       // payload.customer = payload.new_customer;
//       // type === 'activate' && (payload.customer_group2 = '');
//     }
//   }
//   if (
//     payload.program === CreateNew.createNewProgram ||
//     (!payload.program && payload.new_program)
//   ) {
//     if (!payload.new_program) {
//       payload.program = '';
//       if (currentWarnings) {
//         const index = currentWarnings.indexOf('program');
//         index > -1 && currentWarnings.splice(index, 1),
//           (payload['warnings'] = [...currentWarnings]);
//       }
//     } else {
//       payload.program = payload.new_program;
//     }
//   }
// };

// export const getDiffByFormRef = (formRef: any) => {
//   const values = formRef.current.values;
//   const initialValues = formRef.current.initialValues;
//   const updatedValues: any = compareFormValues(values, initialValues);
//   const difference: any = {};
//   if (updatedValues.length) {
//     updatedValues.map((key: any) => {
//       difference[key] = values[key];
//     });
//   }
//   const warnings = values['warnings']?.filter(
//     (warning: string, idx: number) => values['warnings'].indexOf(warning) == idx
//   );
//   return { values, initialValues, updatedValues, difference, warnings };
// };

// export const compareFormValues = (values: any, initialValues: any) => {
//   const comp = _.reduce(
//     values,
//     function (result, value, key: any) {
//       return _.isEqual(value, initialValues[key]) ? result : result.concat(key);
//     },
//     []
//   );
//   return comp;
// };
