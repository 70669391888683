import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import { Formik, Form } from "formik";
import { AlertModal } from "./components/alertmodal";
import Button from "./components/Button";
import DateInput from "./components/date-picker";
import ButtonDropdown from "./components/button-dropdown";
import InputGrp from "./components/input-grp";
import { ReasonModal } from "./components/reason-modal";
import ToolTip from "./components/tooltip";
import ChoiceSelector from "./components/yes-no-selector";
import TextArea from "./components/text-area";
import WithLabelExample from "./components/progress-bar";
import ErrorBoundary from "./components/errorboundary";
import QuantityInput from "./components/quantity-input";
import { ScrollToError } from "./components/ScrollToError";
import SelectElement from "./components/select-element";
import PercentageSelector from "./components/percentage-selector";
import HeatmapRange from "./components/heatmap-range";
import Loader from "./components/loader";
import TabsComponent from "./components/tabs-component";
import Accordion from "./components/accordion";
import RetexTable from "./components/retex-table";
import FileUploader from "./components/file-uploader";
import SelectTypeHead from "./components/select-type-head";
import SummaryBlock from "./components/summary-block";
import DashboardFilters from "./components/dashboard-filters";
import OpenOpportunities from "./components/dashboard/OpenOpportunitiesTable";
import CommentComponent from "./components/comment";
// import SelectTypeHead from './components/select-type-head';

export default function App() {
  const [isLoading, setIsLoading] = useState(true);

  // Simulate loading for demonstration purposes
  setTimeout(() => {
    setIsLoading(false); // Hide the loader after 3 seconds
  }, 3000);
  const handleLogoClick = () => {
    console.log("Logo clicked!");
  };
  // tab
  const handleTabChange = (key: string | null) => {
    console.log("Active Tab:", key);
  };
  const handleLogout = () => {
    console.log("User logged out");
  };

  const handleSettingsClick = () => {
    console.log("Settings clicked!");
  };

  const breadcrumbs = [
    { label: "Aeroport" },
    { label: "Dashboard", isActive: true },
  ];

  const buttons = [
    {
      id: "dashboard",
      label: "Power BI Dashboard",
      iconClass: "icon icon-pie-chart",
      tooltipText: "Go to Power BI dashboard",
      onClick: () => console.log("Navigating to Power BI Dashboard"),
      selectedTab: "dashboard",
    },
    {
      id: "my-task",
      label: "My Task",
      iconClass: "icon icon-article",
      tooltipText: "My task",
      onClick: () => console.log("Navigating to My Task"),
      selectedTab: "my-task",
    },
    {
      id: "home",
      label: "Home",
      iconClass: "iconHome",
      tooltipText: "Home",
      onClick: () => console.log("Navigating to home"),
      selectedTab: "home",
      btnCustomClass: "homeBtnWrapper",
    },
  ];

  const innerSidebarMenu = [
    { id: "rfq", label: "RFQ" },
    { id: "bga", label: "BGA" },
    { id: "pg1", label: "PG1" },
    { id: "nomination", label: "Nomination" },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleShowModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handlePositiveAction = () => {
    console.log("Positive action confirmed");
    setIsModalVisible(false);
  };

  const handleNegativeAction = () => {
    console.log("Negative action confirmed");
    setIsModalVisible(false);
  };

  const btnHandleClick = () => {
    alert("Button clicked!");
  };

  const handleDropdownClick = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };

  const initialValues = {
    date: "",
    standaloneInput: "",
    exampleChoice: "option1",
    reason: "",
    comments: "",
    percentage: "",
    retex: [
      { sl_no: 1, retex_number: "RET123", global_risk: "Low" },
      { sl_no: 2, retex_number: "RET456", global_risk: "High" },
    ],
    typeaheadField: [], // Initial value should match the expected type
  };

  const validationSchema = Yup.object().shape({
    date: Yup.string().required("Date is required"),
    standaloneInput: Yup.string().required("Input is required"),
    exampleChoice: Yup.string().required("Please select an option"),
    reason: Yup.string().required("Reason is required"),
    comments: Yup.string().required("Comments are required"),
    percentage: Yup.string().required("Percentage is required"),
    typeaheadField: Yup.array().required("This field is required"),
  });
  const [rangeValue, setRangeValue] = useState(5);

  const handleRangeChange = (value: number) => {
    setRangeValue(value);
    console.log("Selected Range Value:", value);
  };
  const handleSubmit = (values: any) => {
    console.log("Form values:", values);
  };
  const accordionData = [
    {
      title: "Accordion Item 1",
      body: "This is the body content of accordion item 1.",
    },
    {
      title: "Accordion Item 2",
      body: "This is the body content of accordion item 2.",
    },
    {
      title: "Accordion Item 3",
      body: "This is the body content of accordion item 3.",
    },
  ];
  const retexColumns = [
    {
      dataField: "sl_no",
      text: "SL No",
      headerClasses: "w-auto aeroport-id-col",
    },
    {
      dataField: "retex_number",
      text: "Retex Number",
      headerClasses: "w-auto aeroport-id-col",
    },
    {
      dataField: "global_risk",
      text: "Global Risk",
      headerClasses: "w-auto aeroport-id-col",
    },
  ];
  const handleFileChange = (file: File | FileList) => {
    // Handle file change
    console.log("File selected:", file);
  };

  const handleFileRemove = () => {
    // Handle file removal
    console.log("File removed");
  };

  const handleUpload = (file: File) => {
    // Handle file upload
    console.log("Uploading file:", file);
  };

  const handleChange = (
    allValues: any,
    selectedValue: any,
    setFieldValue: any,
    updateNewOptHandler?: (newOptionsObj: any) => void,
    changeModelData?: (fieldName: string, propToAdd: any) => void,
    options?: any[]
  ) => {
    console.log("Handle change:", selectedValue);
  };

  const updateNewOptHandler = (newOptionsObj: any) => {
    console.log("Update options:", newOptionsObj);
  };

  const changeModelData = (fieldName: string, propToAdd: any) => {
    console.log("Change model data:", fieldName, propToAdd);
  };

  const options = [
    { id: 1, label: "Option 1", value: "Option 1" },
    { id: 2, label: "Option 2", value: "Option 2" },
  ];

  // summary block
  const fileKeyArray = ["document", "fileKey"];

  // Example function for downloading the document
  const downloadDocument = async (file: any) => {
    if (!file) return;
    // Simulate file download or call an API for file download
    console.log("Downloading:", file.originalFileName);
    // You can add your actual download logic here
  };

  // Example data for the summary rows
  const summaryData = [
    {
      rowName: "Document 1",
      rowVal: { originalFileName: "File1.pdf" },
      rowKey: "document",
    },
    {
      rowName: "Document 2",
      rowVal: { originalFileName: "File2.pdf" },
      rowOptionalVal: { originalFileName: "OptionalFile2.pdf" },
      rowKey: "document",
    },
  ];
  // summary block end

  // dashboad filter
  // Define the filters data structure
  const filters = {
    current_status: [
      { id: "active", label: "Active", value: "active" },
      { id: "inactive", label: "Inactive", value: "inactive" },
    ],
    eto_project_manager: [
      { id: "john_doe", label: "John Doe", value: "john_doe" },
      { id: "jane_doe", label: "Jane Doe", value: "jane_doe" },
    ],
    eto_project_level: [
      { id: "level_1", label: "Level 1", value: "level_1" },
      { id: "level_2", label: "Level 2", value: "level_2" },
    ],
  };

  // State to manage selected options
  const [selectedOpts, setSelectedOpts] = useState({});

  // Handle change in filters
  const handleDashboardfilter = (newFilters: any) => {
    setSelectedOpts(newFilters);
    console.log("Selected Filters:", newFilters);
  };

  // Handle filters close (you can implement actual logic as needed)
  const handleFiltersClose = () => {
    console.log("Filters panel closed");
  };

  // Handle filters reset (you can implement actual logic as needed)
  const handleFiltersReset = () => {
    setSelectedOpts({});
    console.log("Filters reset");
  };
  // // opportunitestable
  const [openOpportunities, setOpenOpportunities] = useState<any[]>([]);
  const [isOpenOpportunitiesEmpty, setIsOpenOpportunitiesEmpty] =
    useState<string>("in-progress");
  const [openPage, setOpenPage] = useState<number>(1);
  const [openPageLimit, setOpenPageLimit] = useState<number>(10);
  const [openOpportunityFilters, setOpenFilters] = useState<any>({});
  const [openFilterDropdownValues, setOpenFilterDropdownValues] = useState<any>(
    {}
  );
  const pageLimitOpts = [10, 20, 50, 100];

  // Fetch open opportunities data (replace this with your actual data fetching logic)
  useEffect(() => {
    async function fetchOpenOpportunities() {
      setIsOpenOpportunitiesEmpty("in-progress");
      try {
        // Simulating a fetch call
        const response = await new Promise((resolve) =>
          setTimeout(
            () =>
              resolve([
                {
                  opportunity_id: 1,
                  opportunity_bga: {
                    business_development: "John Doe",
                  },
                  current_status: "On time",
                  opportunity_rfq: { total_net_sales: "$1000" },
                },
                {
                  opportunity_id: 2,
                  opportunity_bga: {
                    business_development: "Jane Doe",
                  },
                  current_status: "Delayed",
                  opportunity_rfq: { total_net_sales: "$2000" },
                },
                {
                  opportunity_id: 2,
                  opportunity_bga: {
                    business_development: "Jane Doe",
                  },
                  current_status: "Delayed",
                  opportunity_rfq: { total_net_sales: "$2000" },
                },
                {
                  opportunity_id: 2,
                  opportunity_bga: {
                    business_development: "Jane Doe",
                  },
                  current_status: "Delayed",
                  opportunity_rfq: { total_net_sales: "$2000" },
                },
              ]),
            1000
          )
        );

        setOpenOpportunities(response as any[]);
        setIsOpenOpportunitiesEmpty((response as any[]).length ? "" : "empty");
      } catch (error) {
        console.error("Error fetching open opportunities:", error);
        setIsOpenOpportunitiesEmpty("empty");
      }
    }

    fetchOpenOpportunities();
  }, []);

  // Example dropdown values (replace with actual data fetching logic)
  useEffect(() => {
    setOpenFilterDropdownValues({
      opportunityIds: [
        { id: 1, label: "Opportunity 1", value: 1 },
        { id: 2, label: "Opportunity 2", value: 2 },
      ],
      business_dev: [
        { id: "John Doe", label: "John Doe" },
        { id: "Jane Doe", label: "Jane Doe" },
      ],
    });
  }, []);
  // comment
  const sampleComment = {
    id: 1,
    text: "This is a comment",
    created_at: "2024-09-11T00:00:00Z",
    updated_at: "2024-09-11T00:00:00Z",
    user: {
      id: 1,
      fullname: "John Doe",
      firstname: "John",
      lastname: "Doe",
      profile_pic_obj: { url: "https://example.com/profile-pic.jpg" },
    },
    is_respond: false,
    is_edited: false,
    responses: [],
  };

  const currentUser = {
    id: 1,
    fullname: "John Doe",
    firstname: "John",
    lastname: "Doe",
    profile_pic_obj: { url: "https://example.com/profile-pic.jpg" },
  };

  const handleResponseAdded = async (comment: string, commentId: number) => {
    console.log("Response added:", comment, commentId);
    return Promise.resolve();
  };

  const handleCommentEdit = async (comment: string, commentId: number) => {
    console.log("Comment edited:", comment, commentId);
    return Promise.resolve();
  };
  return (
    <>
      {isLoading && (
        <Loader
          visible={isLoading}
          color="#b7c9e7"
          autoHideDelay={3000} // Optional: Change if you need a different auto-hide delay
          styles={{
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }} // Optional: Custom styles
        />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <Header
              logoClick={handleLogoClick}
              logoUrl="/images/logo.svg"
              logoAlt="SKF Logo"
              breadcrumbs={breadcrumbs}
              isProduction={true}
              onLogout={handleLogout}
              onSettingsClick={handleSettingsClick}
              navbarExpand="md"
              navbarVariant="dark"
              navbarClassName="justify-content-between header-nav"
              containerClassName="container container--large"
            />

            <div className="d-flex">
              <Sidebar
                stageClickHandler={() => {}}
                buttons={buttons}
                innerSidebarMenu={innerSidebarMenu}
              />

              <div className="d-flex flex-column gap-4 w-75">
                <div>
                  <button type="button" onClick={handleShowModal}>
                    Show Modal
                  </button>
                  <AlertModal
                    show={isModalVisible}
                    headerText="Confirmation"
                    bodyText="Are you sure you want to proceed?"
                    showNegative={true}
                    negativeText="No"
                    positiveText="Yes"
                    onNegative={handleNegativeAction}
                    onPositive={handlePositiveAction}
                    modalSize="sm"
                  />
                </div>

                <div>
                  <Button
                    buttonName="Click Me"
                    className="btn-outline-primary"
                  />
                  <Button
                    buttonName="Submit"
                    onClick={btnHandleClick}
                    className="btn-outline-secondary"
                  />
                  <Button
                    buttonName="Disabled"
                    disabled={true}
                    className="btn-outline-secondary"
                  />
                  <Button buttonName="Styled Button" className="btn-primary" />
                </div>

                <DateInput
                  name="date"
                  label="Select a Date"
                  placeHolder="MM/DD/YYYY"
                  minDate="2023-01-01"
                  maxDate="2025-12-31"
                  dateFormat="yyyy/MM/dd"
                />

                <ButtonDropdown
                  buttonOptions={["Option 1", "Option 2", "Option 3"]}
                  onClick={handleDropdownClick}
                  disabled={false}
                  defaultOption="Option 2"
                  buttonClassName="my-custom-button-class"
                  dropdownClassName="my-custom-dropdown-class"
                  iconSrc="/images/add.svg"
                  dropdownIconSrc="/images/arrow-white-icon.svg"
                  iconSize={20}
                />

                <InputGrp
                  name="standaloneInput"
                  placeHolder="Enter something"
                  WrapperClassName="my-3"
                  showIcon={true}
                  handleChange={({ selectedValue }: any) =>
                    setFieldValue("standaloneInput", selectedValue)
                  }
                />

                <ReasonModal
                  show={true}
                  headerText="Confirm Action"
                  positiveText="Submit"
                  initialValues={{ reason: "" }}
                  validationSchema={Yup.object().shape({
                    reason: Yup.string().required("Reason is required"),
                  })}
                  formFields={[
                    {
                      name: "reason",
                      type: "text",
                      placeholder: "Enter reason",
                    },
                  ]}
                  onPositive={handlePositiveAction}
                />

                <ToolTip
                  tooltipText="This is a tooltip"
                  placement="right"
                  trigger={["hover", "focus"]} // Specify triggers as an array
                  showIcon={true}
                  iconSrc="/images/error_outline.svg"
                  iconAlt="Error Icon"
                  iconHeight={20}
                  iconWidth={20}
                  iconClassName="my-icon-class"
                />

                <ChoiceSelector
                  name="exampleChoice"
                  label="Choose an Option"
                  dataSets={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                  ]}
                  handleChange={(values, selectedValue, setFieldValue) => {
                    setFieldValue("exampleChoice", selectedValue);
                  }}
                  handleBlur={(value) => {
                    console.log("Blurred:", value);
                  }}
                  isExempt={{ fields: [{ field: "exampleChoice" }] }}
                />

                <TextArea
                  name="comments"
                  placeHolder="Enter your comments"
                  cols={5}
                  WrapperClassName="my-3"
                  isDisabled={false}
                />
                <ErrorBoundary>
                  <WithLabelExample percentage={75} />
                  <QuantityInput
                    name="quantity"
                    label="Quantity"
                    placeHolder="Enter Quantity"
                    minimum={0}
                    disable={{ field: "someField", value: "someValue" }} // Example disable condition
                  />
                </ErrorBoundary>
                <ScrollToError
                  bodyText="Please correct the errors in the form before proceeding."
                  positiveText="Okay"
                  negativeText="Close"
                  showNegative={false}
                  onPositive={() => console.log("Error acknowledged")}
                />
                <SelectElement
                  name="yourFieldName"
                  placeholder="Select an option"
                  options={[
                    { label: "Option 1", value: "1" },
                    { label: "Option 2", value: "2" },
                  ]}
                  labelKey="label"
                  valueKey="value"
                  customClass="your-custom-class"
                  handleChange={(value) => console.log(value)}
                />
                <PercentageSelector
                  name="percentage"
                  label="Select Percentage"
                  dataSets={[
                    { value: "25", label: "25%" },
                    { value: "50", label: "50%" },
                    { value: "75", label: "75%" },
                    { value: "100", label: "100%" },
                  ]}
                  handleChange={(value) => setFieldValue("percentage", value)}
                  handleBlur={(value) => console.log("Blurred:", value)}
                  isDisabled={false}
                  formGroupClassName="my-3"
                  OptionClassName="my-option-class"
                  value={values.percentage}
                  error={errors.percentage}
                />

                <HeatmapRange
                  name="heatmapRange"
                  label="Select Range"
                  value={rangeValue.toString()}
                  handleChange={(value) => setRangeValue(value)}
                  onRangeChange={handleRangeChange}
                  isDisabled={false}
                  min={0}
                  max={10}
                  step={1}
                />
                <p>Current Range Value: {rangeValue}</p>

                <TabsComponent
                  defaultTabKey="home"
                  tabs={[
                    {
                      tabKey: "home",
                      title: "Home",
                      content: <div>Home Content</div>,
                    },
                    {
                      tabKey: "profile",
                      title: "Profile",
                      content: <div>Profile Content</div>,
                    },
                    {
                      tabKey: "settings",
                      title: "Settings",
                      content: <div>Settings Content</div>,
                    },
                    {
                      tabKey: "comments",
                      title: "Comments",
                      content: <div>Comments Content</div>,
                      isDisabled: true,
                    },
                  ]}
                  onTabChange={handleTabChange}
                />
                <Accordion accordionArr={accordionData} />
                <RetexTable
                  name="retex"
                  columns={retexColumns}
                  keyField="sl_no"
                />
                <FileUploader
                  id="my-file-uploader"
                  labelBeforeImageSelection="Select an image"
                  labelAfterImageSelected="Image selected"
                  imgWidth={150}
                  imgHeight={150}
                  onFileChange={handleFileChange}
                  onFileRemove={handleFileRemove}
                  onUpload={handleUpload}
                  mediaType="image" // Change as needed: 'video', 'document', 'docSheet'
                  isEditable={true}
                  isDisabled={false}
                  isUploading={false}
                  fieldTouched={false}
                  fieldError=""
                />

                <SelectTypeHead
                  name="typeaheadField"
                  labelKey="label"
                  placeholder="Select or type"
                  multiple={true}
                  options={options}
                  handleChange={handleChange}
                  updateNewOptHandler={updateNewOptHandler}
                  changeModelData={changeModelData}
                  selectedOptions={values.typeaheadField}
                  error={errors.typeaheadField as string} // Cast to string if necessary
                  isDisabled={false}
                />
                <SummaryBlock
                  title="Documents Summary"
                  summaryRowArr={summaryData}
                  fileKeyArray={fileKeyArray}
                  downloadDocument={downloadDocument}
                />
                <DashboardFilters
                  filters={filters}
                  handleChange={handleDashboardfilter}
                  selectedOpts={selectedOpts}
                  onFiltersClose={handleFiltersClose}
                  onFiltersReset={handleFiltersReset}
                  filterReset={false} // You can manage this state based on your reset logic
                  labelsForFilters={{
                    current_status: "Current Status (OTD)",
                    eto_project_manager: "ETO Project Manager",
                    eto_project_level: "ETO Project Level",
                  }}
                  classNames={{
                    wrapper: "filters-wrapper",
                    header: "filters-header",
                    body: "filters-body",
                    buttonWrapper: "filters-button-wrapper",
                    applyButton: "apply-button",
                    resetButton: "reset-button",
                    typeaheadWrapper: "typeahead-wrapper",
                    typeahead: "typeahead",
                    multiSelectWrapper: "multi-select-wrapper",
                  }}
                />
                <OpenOpportunities
                  style={{}} // Pass your style object here
                  openOpportunities={openOpportunities}
                  currentStage="stage1" // Example stage, modify as needed
                  isOpenOpportunitiesEmpty={isOpenOpportunitiesEmpty}
                  setOpenPage={setOpenPage}
                  openPage={openPage}
                  openPageLimit={openPageLimit}
                  setOpenPageLimit={setOpenPageLimit}
                  openOpportunityFilters={openOpportunityFilters}
                  setOpenFilters={setOpenFilters}
                  openFilterDropdownValues={openFilterDropdownValues}
                  pageLimitOpts={pageLimitOpts}
                />
                <CommentComponent
                  comment={sampleComment}
                  onResponseAdded={handleResponseAdded}
                  onCommentEdit={handleCommentEdit}
                  currentUser={currentUser}
                />
                <button type="submit">Submit All</button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
