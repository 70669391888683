// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btnDropdown .btn,
.btnDropdown .dropdown {
  background-color: #0f58d6;
  border-radius: 0;
}
.btnDropdown .btn {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.btnDropdown .btn span {
  margin-right: 2px;
}

.dropdown-toggle::after {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/button-dropdown/button-dropdown.scss"],"names":[],"mappings":"AAEE;;EAEE,yBAAA;EACA,gBAAA;AADJ;AAGE;EACE,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAEI;EACE,iBAAA;AAAN;;AAKA;EACE,aAAA;AAFF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
