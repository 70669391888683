import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
  camelCase,
  capitalCase,
  getDownloadMediaUrl,
  getTimeTag,
} from '../../utils/helper';
import  './comment.scss';
import CommentInput from '../comment-input';

type User = {
  id: number;
  fullname: string;
  firstname: string;
  lastname: string;
  profile_pic_obj: { url: string };
};

type Comment = {
  id: number;
  text: string;
  created_at: string;
  updated_at: string;
  opportunity?: any;  // Optional
  user: User;
  is_respond: boolean;
  response_comment?: any;  // Optional
  is_edited: boolean;
  responses: Comment[] | null;
};

interface Props {
  comment: Comment;
  onResponseAdded: (comment: string, commentId: number) => Promise<any>;
  onCommentEdit: (comment: string, commentId: number) => Promise<any>;
  currentUser: User;
}

const CommentComponent: React.FC<Props> = ({ comment, onResponseAdded, onCommentEdit, currentUser }) => {
  const [isReply, setIsReply] = useState<number | null>(null);
  const [commentEdit, setCommentEdit] = useState<number | null>(null);

  const handleCommentAdded = async (text: string, type: 'reply' | 'edit', commentId: number) => {
    let response;
    if (type === 'reply') {
      response = await onResponseAdded(text, commentId);
      setIsReply(null);  // Clear reply state
    } else if (type === 'edit') {
      response = await onCommentEdit(text, commentId);
      setCommentEdit(null);  // Clear edit state
    }
    return response;
  };

  const renderCommentBlock = (commentDetails: Comment) => (
    <div className={`commnentMain`}>
      <div className="d-flex imgtextwrap">
        <div className="imgleft">
          {commentDetails.user.profile_pic_obj ? (
            <img
              src={getDownloadMediaUrl(commentDetails.user.profile_pic_obj.url)}
              alt={`${commentDetails.user.fullname || 'user'} image`}
              width={50}
              height={50}
            />
          ) : (
            <>
              <span>{commentDetails.user.firstname.charAt(0)}</span>
              <span>{commentDetails.user.lastname.charAt(0)}</span>
            </>
          )}
        </div>
        <div className={`contentright commnentcontentright`}>
          <div className="contentrightinr w-100 d-flex justify-content-between">
            <div className="user-detai d-flex">
              <h5 className="font-skf user-name font-bold font-size-14 text-black">
                {commentDetails.user.fullname
                  ? capitalCase(commentDetails.user.fullname)
                  : 'User'}
              </h5>
              <p className={`timeTag font-normal m-0 pb-0 font-skf font-size-10 text-gray-600`}>
                {`${getTimeTag(commentDetails.updated_at)} ago.`}
              </p>
              {commentDetails.is_edited && (
                <p className={`timeTag mb-0 text-gray-600 font-skf font-size-10`}>
                  Edited.
                </p>
              )}
            </div>
            <div className="d-flex">
              {!commentDetails.is_respond && currentUser.id && (
                <span
                  className={`replyTag text-primary-600 font-skf font-size-10`}
                  onClick={() => setIsReply(commentDetails.id)}
                >
                  <img src="/images/reply.svg" alt="reply icon" width={24} height={20} />
                  Reply
                </span>
              )}
              {currentUser.id === commentDetails.user.id && (
                <span
                  className={`replyTag mx-2`}
                  onClick={() => setCommentEdit(commentDetails.id)}
                >
                  <img src="/images/edit-outline.svg" alt="edit icon" width={20} height={20} />
                </span>
              )}
              {((commentEdit && commentEdit === commentDetails.id) || (isReply && isReply === commentDetails.id)) && (
                <span
                  className={`replyTag mx-3`}
                  onClick={() => {
                    setCommentEdit(null);
                    setIsReply(null);
                  }}
                >
                  <img src="/images/cancel.svg" alt="cancel icon" width={14} height={14} />
                </span>
              )}
            </div>
          </div>
          {(!commentEdit || commentEdit !== commentDetails.id) && (
            <div className={`commentText`}>
              <p className="font-skf font-size-12 mb-0 font-normal text-black">
                {commentDetails.text ? camelCase(commentDetails.text) : ''}
              </p>
            </div>
          )}
        </div>
      </div>
      {commentEdit === commentDetails.id && (
        <CommentInput
          placeholderText="Please enter updated comment"
          text={commentDetails.text}
          onSubmit={(text) => handleCommentAdded(text, 'edit', commentDetails.id)}
        />
      )}
    </div>
  );

  return (
    <div className={`commentWrapper`}>
      {comment && renderCommentBlock(comment)}
      {isReply && comment && isReply === comment.id && (
        <CommentInput
          placeholderText="Please enter reply comment"
          onSubmit={(text) => handleCommentAdded(text, 'reply', comment.id)}
        />
      )}
      {comment.responses && comment.responses.length > 0 && (
        <div className={'replyContainer'}>
          {comment.responses.map((response) => (
            <div className={'replyWrapper'} key={response.id}>
              {renderCommentBlock(response)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CommentComponent;
