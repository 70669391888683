import React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
import _ from 'lodash';

// Define the props interface
interface CommentInputProps {
  text?: string;
  placeholderText: string;
  onSubmit: (text: string) => Promise<any>;
  inputClass?: string;
  fullWidth?: boolean;
  buttonIconUrl?: string; // Allow the button icon to be customizable
  validationSchema?: Yup.ObjectSchema<{ text: string }>; // Correctly typed validation schema
  onButtonClick?: () => void; // Additional action on button click
}

const CommentInput: React.FC<CommentInputProps> = ({
  text = '',
  placeholderText,
  fullWidth = false,
  onSubmit,
  inputClass = '',
  buttonIconUrl = '/images/send.svg',
  validationSchema,
  onButtonClick,
}) => {
  // Use the provided validation schema or fallback to default
  const commentSchema = validationSchema || Yup.object().shape({
    text: Yup.string().required('Please enter a comment'),
  });

  const initialComment = { text };

  return (
    <div className={`${fullWidth ? 'w-100' : ''}`}>
      <Formik
        validationSchema={commentSchema}
        enableReinitialize
        initialValues={initialComment}
        onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
          setSubmitting(true);
          const response = await onSubmit(values.text);
          if (!response.error) {
            resetForm();
            setErrors({});
          }
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          submitForm,
        }) => (
          <FormikForm>
            <Row className="m-0 justify-content-center">
              <Col md={10} className="p-0">
                <Form.Control
                  className={inputClass}
                  name="text"
                  type="text"
                  value={values.text}
                  placeholder={placeholderText}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              <Col
                md={1}
                className={`d-flex p-0 align-items-center`}
              >
                <button
                  type="submit"
                  onClick={() => {
                    onButtonClick?.();
                    submitForm();
                  }}
                >
                  <img
                    src={buttonIconUrl}
                    alt="comment-update"
                    width={25}
                    height={25}
                  />
                </button>
              </Col>
            </Row>
            {errors.text && touched.text && (
              <small className="text-danger">{errors.text}</small>
            )}
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default CommentInput;
