import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next'; // Ensure proper types are imported
import './retex-table.scss'; // Adjust the path as necessary

interface RetexTableProps {
  name: string;
  columns: ColumnDescription[]; // Define the type of columns
  keyField: string;
}

const RetexTable: React.FC<RetexTableProps> = ({ name, columns, keyField }) => {
  const [retexData, setRetexData] = useState<any[]>([]);
  const { values }: any = useFormikContext();

  useEffect(() => {
    if (name && values[name]) {
      setRetexData(values[name]);
    }
  }, [name, values]);

  return (
    <div className={`retex_table`}>
      <BootstrapTable
        headerClasses={`header`}
        rowClasses={`row`}
        bootstrap4
        hover
        bordered={false}
        striped
        keyField={keyField}
        data={retexData}
        columns={columns}
        filter={false}
      />
    </div>
  );
};

export default RetexTable;
