// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commnentMain :global .imgtextwrap .imgleft {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  border-radius: 100px;
  background-color: #0f58d6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.commnentMain :global .imgtextwrap .imgleft span {
  color: #ffffff;
  font-size: 16px;
}
.commnentMain :global .imgtextwrap h5 {
  line-height: 20px;
  letter-spacing: 0em;
}
.commnentMain .timeTag {
  line-height: 14px;
  letter-spacing: 0.25px;
  padding: 3px 0px 0px 8px;
  height: 17px;
}

.commentText {
  width: 100%;
}
.commentText p {
  line-height: 16px;
  letter-spacing: 0.25px;
}

.commentWrapper {
  width: 100%;
}

.commentWrapper,
.replyWrapper {
  color: #273342;
}

.commentWrapper {
  width: 95%;
  margin: 0px auto;
}

.commnentcontentright {
  width: calc(100% - 70px);
}

.replyWrapper {
  padding: 5px;
  background: #f6f6f6;
  margin-left: 61px;
  border-radius: 10px;
}

.replyContainer {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 24px 0px 12px;
}

.replyTag {
  font-weight: 600;
  cursor: pointer;
  height: 20px;
  display: flex;
  align-items: center;
}
.replyTag img {
  padding-right: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/comment/comment.scss"],"names":[],"mappings":"AAGM;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,oBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAFR;AAGQ;EACE,cAAA;EACA,eAAA;AADV;AAIM;EACE,iBAAA;EACA,mBAAA;AAFR;AAME;EACE,iBAAA;EACA,sBAAA;EACA,wBAAA;EACA,YAAA;AAJJ;;AAQA;EACE,WAAA;AALF;AAME;EACE,iBAAA;EACA,sBAAA;AAJJ;;AAQA;EACE,WAAA;AALF;;AAOA;;EAEE,cAAA;AAJF;;AAOA;EACE,UAAA;EACA,gBAAA;AAJF;;AAOA;EACE,wBAAA;AAJF;;AAMA;EACE,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;AAHF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;AAFF;;AAIA;EACE,gBAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AADF;AAEE;EACE,kBAAA;AAAJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
