import React, { memo, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import './select-type-head.scss';
import { useFormikContext } from 'formik';
import lodash from 'lodash';

interface Props {
  options?: any;
  optionsOfAll?: any[];
  labelKey?: string;
  placeholder?: string;
  name: string;
  multiple?: boolean;
  handleChange?: (
    allValues: any,
    selectedValue: any,
    setFieldValue: any,
    updateNewOptHandler?: (newOptionsObj: any) => void,
    changeModelData?: (fieldName: string, propToAdd: any) => void,
    options?: any[]
  ) => void;
  error?: string;
  isDisabled?: boolean;
  updateNewOptHandler: (newOptionsObj: any) => void;
  changeModelData: (fieldName: string, propToAdd: any) => void;
  selectedOptions?: any;
}

const TypeAheadDropDown: React.FC<Props> = ({
  placeholder = 'Type or select',
  name = '',
  multiple = false,
  isDisabled = false,
  error = '',
  options = [],
  labelKey = 'label',
  selectedOptions = [],
  handleChange,
  ...props
}) => {
  const defaultOptions = [
    { id: 1, label: 'Option 1', value: 'Option 1' },
    { id: 2, label: 'Option 2', value: 'Option 2' },
  ];

  const dropdownOptions = options.length > 0 ? options : defaultOptions;
  const { values, errors, setFieldValue, touched }: any = useFormikContext();
  const typeaheadRef: any = useRef(null);

  return (
    <>
      <div className={`typehead_wrapper`}>
        <Typeahead
          inputProps={{
            className: 'text-truncate',
          }}
          ref={typeaheadRef}
          className={`${multiple ? 'multiSelectWrapper' : ''}`}
          id={name}
          multiple={multiple}
          disabled={isDisabled}
          options={dropdownOptions}
          labelKey={(option: any) => `${option[labelKey]}`}
          placeholder={placeholder}
          flip={true}
          selected={selectedOptions}
          onChange={(opt) => {
            const ids = lodash.map(opt, 'value');
            const [val] = ids;
            if (handleChange) {
              handleChange(
                values,
                multiple ? ids : val,
                setFieldValue,
                props.updateNewOptHandler,
                props.changeModelData,
                props.optionsOfAll
              );
            }
            setFieldValue(name, multiple ? ids : val);
          }}
          onBlur={() => {
            const dropInput = typeaheadRef.current.getInput().value;
            if (!(values && values[name]) && dropInput) {
              typeaheadRef?.current?.clear();
              setFieldValue(name, null);
            }
          }}
          filterBy={(option: any, { text, selected }: any) => {
            if (selected.length && !multiple) {
              return true;
            }
            return option.value.toLowerCase().indexOf(text.toLowerCase()) !== -1;
          }}
        />
      </div>
      {touched[name] && errors[name] && (
        <small className='text-danger'>{errors[name]}</small>
      )}
    </>
  );
};

export default memo(TypeAheadDropDown);
