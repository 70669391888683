// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.typehead_wrapper {
  position: relative;
}
.typehead_wrapper:global(::after) {
  content: " ";
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(calc(-50% - 2px)) rotate(45deg);
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 0px;
  width: 8px;
  height: 8px;
  pointer-events: none;
  cursor: pointer;
}
.typehead_wrapper .multiSelectWrapper > div {
  display: flex;
  align-items: center;
}
.typehead_wrapper .multiSelectWrapper :global .rbt-input {
  height: auto !important;
}
.typehead_wrapper .multiSelectWrapper input::placeholder {
  color: var(--gray-500);
}`, "",{"version":3,"sources":["webpack://./src/components/select-type-head/select-type-head.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,YAAA;EACA,kBAAA;EACA,WAAA;EACA,QAAA;EACA,qDAAA;EACA,mBAAA;EACA,yBAAA;EACA,qBAAA;EACA,YAAA;EACA,UAAA;EACA,WAAA;EACA,oBAAA;EACA,eAAA;AAEJ;AACI;EACE,aAAA;EACA,mBAAA;AACN;AAEM;EACE,uBAAA;AAAR;AAKM;EACE,sBAAA;AAHR","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
