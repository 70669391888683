import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import { Typeahead } from 'react-bootstrap-typeahead';
import { RotatingLines } from 'react-loader-spinner';

interface OpenOpportunitiesProps {
  style: any;
  openOpportunities: any;
  currentStage: string;
  isOpenOpportunitiesEmpty: string;
  setOpenPage: (page: number) => void;
  openPage: number;
  openPageLimit: number;
  setOpenPageLimit: (limit: number) => void;
  openOpportunityFilters: any;
  setOpenFilters: (filters: any) => void;
  openFilterDropdownValues: any;
  pageLimitOpts: number[];
}

export const generatePaginationArray = (
  currentPage: number,
  totalItems: number,
  itemsPerPage: number,
  displayPages: number
) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pages: any[] = [];

  if (totalPages >= 2) {
    pages.push('<');
  }

  if (totalPages <= displayPages) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    const halfDisplay = Math.floor(displayPages / 2);
    let startPage = currentPage - halfDisplay;
    let endPage = currentPage + halfDisplay;

    if (startPage < 1) {
      startPage = 1;
      endPage = displayPages;
    } else if (endPage > totalPages) {
      endPage = totalPages - displayPages + 1;
      startPage = totalPages - displayPages + 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (Number(pages[pages.length - 1]) < totalPages) {
      pages.push('...');
    }
  }

  if (pages[pages.length - 1] !== totalPages) {
    pages.push(totalPages);
  }

  if (totalPages >= 2) {
    pages.push('>');
  }

  return pages;
};

const OpenOpportunities: React.FC<OpenOpportunitiesProps> = ({
  style,
  openOpportunities,
  currentStage,
  isOpenOpportunitiesEmpty,
  setOpenPage,
  openPage,
  openPageLimit,
  setOpenPageLimit,
  openOpportunityFilters,
  setOpenFilters,
  openFilterDropdownValues,
  pageLimitOpts,
}) => {
  const columns: any = [
    {
      dataField: 'opportunity_id',
      text: 'Aeroport ID',
      filter: customFilter({}),
      filterRenderer: (onFilter: any, column: any) => (
        <span onClick={(e) => e.stopPropagation()}>
          <div className={style.typehead_wrapper}>
            <Typeahead
              inputProps={{
                className: 'text-truncate',
              }}
              id={'id'}
              options={
                openFilterDropdownValues.opportunityIds
                  ? [
                      { id: 'all', label: 'All', value: '' },
                      ...openFilterDropdownValues.opportunityIds,
                    ]
                  : []
              }
              placeholder={'Type or select'}
              onChange={(opt: any) => {
                if (opt.length !== 0) {
                  setOpenFilters({
                    ...openOpportunityFilters,
                    opportunity_ids: opt[0].value,
                  });
                }
              }}
            />
          </div>
        </span>
      ),
      sort: true,
      onSort: (columnName: any, sortOrder: string) => {
        setOpenFilters({
          ...openOpportunityFilters,
          sortBy: 'opportunity_id',
          sortOrder: sortOrder.toUpperCase(),
        });
      },
    },
    {
      dataField: 'responsible',
      text: 'Business Dev',
      filter: customFilter({}),
      filterRenderer: (onFilter: any, column: any) => (
        <span onClick={(e) => e.stopPropagation()}>
          <div className={style.typehead_wrapper}>
            <Typeahead
              inputProps={{
                className: 'text-truncate',
              }}
              id={'business_dev'}
              options={
                openFilterDropdownValues.business_dev
                  ? [
                      { id: '', label: 'All', value: '' },
                      ...openFilterDropdownValues.business_dev,
                    ]
                  : []
              }
              placeholder={'Type or select'}
              onChange={(opt: any) => {
                if (opt.length !== 0) {
                  setOpenFilters({
                    ...openOpportunityFilters,
                    business_dev: opt[0].id,
                  });
                }
              }}
            />
          </div>
        </span>
      ),
      sort: true,
      onSort: (columnName: any, sortOrder: string) => {
        setOpenFilters({
          ...openOpportunityFilters,
          sortBy: 'user',
          sortOrder: sortOrder.toUpperCase(),
        });
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      filter: customFilter({}),
      filterRenderer: (onFilter: any, column: any) => (
        <span onClick={(e) => e.stopPropagation()}>
          <div className={style.typehead_wrapper}>
            <Typeahead
              inputProps={{
                className: 'text-truncate',
              }}
              id={'Status'}
              options={[
                { id: 'all', label: 'All', value: '' },
                { id: 'ontime', label: 'On time', value: 'On time' },
                { id: 'delayed', label: 'Delayed', value: 'Delayed' },
              ]}
              placeholder={'Type or select'}
              onChange={(opt: any) => {
                if (opt.length !== 0) {
                  setOpenFilters({
                    ...openOpportunityFilters,
                    status: opt[0].value,
                  });
                }
              }}
            />
          </div>
        </span>
      ),
      sort: true,
      onSort: (columnName: any, sortOrder: string) => {
        setOpenFilters({
          ...openOpportunityFilters,
          sortBy: 'current_status',
          sortOrder: sortOrder.toUpperCase(),
        });
      },
    },
    {
      dataField: 'revenue',
      text: 'Potential Revenue',
      sort: true,
      onSort: (columnName: any, sortOrder: string) => {
        setOpenFilters({
          ...openOpportunityFilters,
          sortBy: 'revenue',
          sortOrder: sortOrder.toUpperCase(),
        });
      },
    },
  ];

  const getOpenOpportunities: any = (data: any) => {
    return data.map((val: any) => {
      const users = val?.opportunity_bga?.business_development?.split(',');
      const oppIdJsx = (
        <div className={`${val.warnings ? 'text-danger fw-bolder' : ''}`}>
          {val.opportunity_id || '-'}
          {val.warnings && (
            <span className='icon icon-action-danger text-danger ms-1'>
              &nbsp;
            </span>
          )}
        </div>
      );
      return {
        ...val,
        opportunity_id: val.warnings?.length ? (
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip className='mytooltip' show={true}>
                <b>Warning Source: </b>
                {val.warnings
                  .map((formName: string) => formName.toUpperCase())
                  .join(', ')}
              </Tooltip>
            }
          >
            {oppIdJsx}
          </OverlayTrigger>
        ) : (
          oppIdJsx
        ),
        responsible: (
          <>
            {val.opportunity_bga?.business_development
              ? users.map((user: string, idx: number) => {
                  const creatorFullNameArr = user.split(' ') ?? [];
                  return (
                    <div
                      className={
                        style.imgtextwrap +
                        '  d-flex justify-content-center align-items-center'
                      }
                      key={idx}
                    >
                      <div className='imgleft'>
                        <span>
                          {(creatorFullNameArr[0] ?? '')
                            .toUpperCase()
                            .charAt(0)}
                        </span>
                        <span>
                          {(creatorFullNameArr[1] ?? '')
                            .toUpperCase()
                            .charAt(0)}
                        </span>
                      </div>
                      <div> {user}</div>
                    </div>
                  );
                })
              : '-'}
          </>
        ),
        status: (
          <div className={style.statusWrapper}>
            <div
              className={`${style.status} ${
                val.current_status === 'Delayed' ? style.red : style.green
              }`}
            >
              {val.current_status}
            </div>
          </div>
        ),
        revenue: val.opportunity_rfq
          ? val.opportunity_rfq.total_net_sales
          : '-',
      };
    });
  };

  if (isOpenOpportunitiesEmpty === 'in-progress') {
    return (
      <div style={{ minHeight: '150px' }}>
        <RotatingLines
          strokeColor='#007aff'
          strokeWidth='5'
          animationDuration='0.75'
          width='50'
          visible={true}
        />
      </div>
    );
  }

  if (!openOpportunities.length) {
    return (
      <div style={{ textAlign: 'center', fontWeight: '500', padding: '15px' }}>
        No Opportunities available at the moment.
      </div>
    );
  }

  const totalItems = openOpportunities.length;
  const displayPages = 5; // Or you can make this a prop if you want it to be customizable
  const paginationArray = generatePaginationArray(
    openPage,
    totalItems,
    openPageLimit,
    displayPages
  );

  return (
    <div>
      <BootstrapTable
        keyField='opportunity_id'
        data={getOpenOpportunities(openOpportunities)}
        columns={columns}
        bordered={false}
        noDataIndication='No Opportunities available at the moment'
        filter={filterFactory()}
      />

      <div className='pagination-container'>
        <ul className='pagination'>
          {paginationArray.map((page, index) => (
            <li
              key={index}
              className={`page-item ${
                page === openPage ? 'active' : ''
              } ${page === '...' ? 'disabled' : ''}`}
              onClick={() => {
                if (page === '<') {
                  setOpenPage(openPage - 1);
                } else if (page === '>') {
                  setOpenPage(openPage + 1);
                } else if (page !== '...') {
                  setOpenPage(Number(page));
                }
              }}
            >
              <span className='page-link'>{page}</span>
            </li>
          ))}
        </ul>

        <Form.Control
          as='select'
          value={openPageLimit}
          onChange={(e) => setOpenPageLimit(Number(e.target.value))}
        >
          {pageLimitOpts.map((limit, index) => (
            <option key={index} value={limit}>
              {limit} items per page
            </option>
          ))}
        </Form.Control>
      </div>
    </div>
  );
};

export default OpenOpportunities;
