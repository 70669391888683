import React, { memo } from 'react';
import './summary-block.scss';
import { Col, Row } from 'react-bootstrap';
import Loader from '../loader';

interface RowProps {
  rowName: string;
  rowVal: any;
  rowKey?: string;
  rowOptionalVal?: any;
}

interface SummaryProps {
  title: string;
  summaryRowArr: RowProps[];
  fileKeyArray: string[];
  downloadDocument: (file: any) => Promise<void>; // Prop for file download
}

const SummaryBlock = memo(
  ({
    title = '',
    summaryRowArr = [],
    fileKeyArray = [],
    downloadDocument,
  }: SummaryProps) => {
    const [loaderActive, setLoaderActive] = React.useState(false);

    const handleDownload = async (file: any) => {
      try {
        setLoaderActive(true);
        await downloadDocument(file);
      } finally {
        setLoaderActive(false);
      }
    };

    return (
      <>
        <Loader visible={loaderActive} />
        <div className={`summary_block_wrapper} mb-3`}>
          <div className={`summary_title`}>{title}</div>
          <div className={`summary_body`}>
            {summaryRowArr.map((item, index) => (
              <Row className={`summary_row`} key={index}>
                <Col>{item.rowName}</Col>
                <Col className={`row_val`}>
                  {fileKeyArray.includes(item.rowKey || '') ? (
                    <>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDownload(item.rowVal);
                        }}
                      >
                        {item.rowVal?.originalFileName || '-'}
                      </a>
                      {item.rowOptionalVal && (
                        <>
                          {item.rowVal && <span>{', '}</span>}
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDownload(item.rowOptionalVal);
                            }}
                          >
                            {item.rowOptionalVal?.originalFileName || ''}
                          </a>
                        </>
                      )}
                    </>
                  ) : (
                    item.rowVal || '-'
                  )}
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </>
    );
  }
);

export default SummaryBlock;
