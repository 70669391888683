import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import _ from "lodash";
import { Button } from "react-bootstrap";

interface FilterOption {
  id: string;
  label: string;
  value: string;
}

interface Props {
  filters: Record<string, FilterOption[]>;
  handleChange: (value: Record<string, string | null>) => void;
  selectedOpts: Record<string, string | null>;
  onFiltersClose: () => void;
  onFiltersReset: () => void;
  filterReset: boolean;
  labelsForFilters?: Record<string, string>;
  warningOptions?: FilterOption[];
  classNames?: {
    wrapper?: string;
    header?: string;
    body?: string;
    buttonWrapper?: string;
    applyButton?: string;
    resetButton?: string;
    typeaheadWrapper?: string;
    typeahead?: string;
    multiSelectWrapper?: string;
  };
}

const DashboardFilters: React.FC<Props> = ({
  filters,
  handleChange,
  selectedOpts,
  onFiltersClose,
  onFiltersReset,
  filterReset,
  labelsForFilters = {},
  warningOptions = [
    { id: "with_warning", label: "With Warning", value: "with_warning" },
    {
      id: "without_warning",
      label: "Without Warning",
      value: "without_warning",
    },
  ],
  classNames = {},
}) => {
  const [filterData, setData] = useState<any>(null);
  const [selectedFilters, setSelectedFilters] = useState<any>(null);
  const [initFilters, setFilters] = useState<any>(null);

  useEffect(() => {
    const comp = initFilters
      ? _.reduce(
          filters,
          function (result, value, key: any) {
            return _.isEqual(value, initFilters[key])
              ? result
              : result.concat(key);
          },
          []
        )
      : [];
    if (!initFilters || (initFilters && comp.length)) {
      const filterVal: any = { opportunity: null };
      const formattedFilters = Object.keys(filters).map((key: any) => {
        const filter = filters[key].map((value: any) => {
          return {
            id: value.id || value.gadId || value.email || value,
            label: value.label || value.name || value,
            value: value.value || value.email || value,
          };
        });
        filterVal[key] = null;
        return { key, filter };
      });
      if (!selectedFilters) setSelectedFilters(filterVal);
      setFilters(filters);
      setData([
        ...formattedFilters,
        { key: "opportunity_by_warning", filter: warningOptions },
      ]);
    }
  }, [filters]);

  const onFiltersClear = () => {
    const clearedFilters: any = {};
    selectedFilters &&
      Object.keys(selectedFilters).map((key: any) => {
        clearedFilters[key] = null;
      });
    handleChange(clearedFilters);
  };

  useEffect(() => {
    setSelectedFilters({ ...selectedFilters, ...selectedOpts });
  }, [selectedOpts]);

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.header}>
        <div>Filters</div>
        <div onClick={onFiltersClose} className="closeBtn"></div>
      </div>
      <div className={classNames.body}>
        {filterData?.length
          ? filterData.map((data: any, index: number) => {
              const isMultiple = [
                "application_engineer",
                "sales",
                "business_dev",
              ].includes(data.key);
              return (
                <div className={classNames.typeaheadWrapper} key={index}>
                  <p>{`${labelsForFilters[data.key] || data.key}`}</p>
                  <Typeahead
                    inputProps={{
                      className: "text-truncate",
                    }}
                    filterBy={(option: any, props) => {
                      if (props.text && !props.selected?.length) {
                        return option.value
                          ?.toLowerCase()
                          ?.startsWith(props.text?.toLowerCase());
                      } else if (props.selected.length) {
                        return true;
                      }
                      return true;
                    }}
                    clearButton
                    className={`${classNames.typeahead} ${
                      isMultiple ? classNames.multiSelectWrapper : ""
                    }`}
                    multiple={isMultiple}
                    id={data.key}
                    selected={[
                      { id: "all", label: "All", value: "all" },
                      ...data.filter,
                    ].filter((opt: any) => {
                      const val = isMultiple
                        ? selectedFilters[data.key]
                            ?.split(",")
                            ?.includes(opt.id)
                        : opt.id == selectedFilters[data.key];
                      return val;
                    })}
                    options={[
                      { id: "all", label: "All", value: "all" },
                      ...data.filter,
                    ]}
                    placeholder={"Type or select"}
                    onInputChange={() => {
                      const filterData = JSON.parse(
                        JSON.stringify(selectedFilters)
                      );
                      filterData[data.key] = "";
                      setSelectedFilters(filterData);
                    }}
                    onChange={(opt: any) => {
                      const filterData = JSON.parse(
                        JSON.stringify(selectedFilters)
                      );
                      const ids = _.map(opt, "id");
                      const [val] = ids;
                      filterData[data.key] = isMultiple ? ids?.join(",") : val;
                      setSelectedFilters(filterData || null);
                    }}
                  />
                </div>
              );
            })
          : null}
      </div>
      <div className={classNames.buttonWrapper}>
        <Button
          onClick={() => {
            handleChange(selectedFilters);
          }}
          className={classNames.applyButton}
        >
          Apply Now
        </Button>
        <Button onClick={onFiltersClear} className={classNames.resetButton}>
          Reset All
        </Button>
      </div>
    </div>
  );
};

export default DashboardFilters;
