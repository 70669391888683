// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary_block_wrapper {
  background-color: #ebecee;
  border-radius: 5px;
  padding: 5px 10px;
}
.summary_block_wrapper .summary_title {
  color: #676f7c;
  padding-bottom: 5px;
  font-weight: 700;
}
.summary_block_wrapper .summary_body .summary_row {
  background-color: #f6f6f6;
  margin: 0;
  border-bottom: 1px solid #ebecee;
  color: #8b919b;
  font-size: 14px;
  align-items: center;
  padding: 3px 0px;
}
.summary_block_wrapper .summary_body .summary_row .row_val {
  color: #313c4b;
}`, "",{"version":3,"sources":["webpack://./src/components/summary-block/summary-block.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,iBAAA;AACF;AAAE;EACE,cAAA;EACA,mBAAA;EACA,gBAAA;AAEJ;AACI;EACE,yBAAA;EACA,SAAA;EACA,gCAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;AACN;AAAM;EACE,cAAA;AAER","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
