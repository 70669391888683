// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file_upload_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #273342;
  width: 100%;
  border-radius: 2px;
  height: 60px;
}
.file_upload_wrapper.disableInput {
  background-color: var(--bs-secondary-bg);
  pointer-events: none;
}

.placeholderText {
  cursor: pointer;
}

.document_name {
  padding-top: 1px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}
.document_name span {
  margin-top: 2px;
  color: var(--primary-600);
  word-break: break-word;
}`, "",{"version":3,"sources":["webpack://./src/components/file-uploader/file-uploader.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,0BAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;AACF;AAAE;EACE,wCAAA;EACA,oBAAA;AAEJ;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;AACF;AAAE;EACE,eAAA;EACA,yBAAA;EACA,sBAAA;AAEJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
