import React, { ReactElement } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface AlertModalProps {
  show: boolean;
  headerText?: string;
  bodyText: string | ReactElement;
  showNegative?: boolean;
  closeButton?: boolean;
  negativeText?: string;
  positiveText: string;
  modalSize?: 'sm' | 'xl' | 'lg';
  onNegative?: () => void;
  onPositive: (type?: string) => void;
}

export const AlertModal: React.FC<AlertModalProps> = ({
  show,
  headerText,
  bodyText,
  showNegative = false,
  closeButton = true,
  negativeText = 'Cancel',
  positiveText,
  onNegative,
  onPositive,
  modalSize = 'sm',
  ...restProps
}) => {
  return (
    <Modal
      {...restProps}
      size={modalSize}
      show={show}
      onHide={onNegative}
    >
      {headerText && (
        <Modal.Header closeButton={closeButton}>
          <Modal.Title>{headerText}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        {typeof bodyText === 'string' ? <p>{bodyText}</p> : bodyText}
      </Modal.Body>
      <Modal.Footer>
        {showNegative && (
          <Button variant="outline-dark" onClick={onNegative}>
            {negativeText}
          </Button>
        )}
        <Button variant="primary" onClick={() => onPositive()}>
          {positiveText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
