import React from "react";
import Navbar from "react-bootstrap/Navbar";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.scss";
type Breadcrumb = {
  label: string;
  isActive?: boolean;
};

type Props = {
  logoClick?: () => void;
  logoClassName?: string;
  logoWidth?: string;
  logoUrl?: string; // Make optional
  logoAlt?: string; // Make optional
  breadcrumbs?: Breadcrumb[];
  isProduction?: boolean;
  navbarExpand?: "sm" | "md" | "lg" | "xl" | boolean;
  navbarVariant?: "light" | "dark";
  navbarClassName?: string;
  containerClassName?: string;
  breadcrumbClassName?: string;
  NavRightClassName?: string;
  SettingIconClass?: string;
  logoutClassName?: string;
  onLogout?: () => void;
  onSettingsClick?: () => void;
};

const Header = (props: Props) => {
  const {
    logoClick = () => {},
    logoClassName = "d-block",
    logoWidth = "100",
    logoUrl = "http://localhost:3002/images/logo.svg", // Default value
    logoAlt = "Default Logo", // Default value
    breadcrumbs = [{ label: "Dashboard", isActive: true }],
    breadcrumbClassName = "breadcrumbs d-flex flex-wrap m-0 font-skf text-white font-size-18",
    NavRightClassName = "d-flex align-items-center nav-right",
    SettingIconClass = "icon icon-settings",
    isProduction = true,
    navbarExpand = "lg",
    navbarVariant = "light",
    navbarClassName = "justify-content-between header-nav navbar",
    logoutClassName = "ml20 text-white cursor-pointer",
    containerClassName,
    onLogout = () => {},
    onSettingsClick = () => {},
  } = props;

  return (
    <Navbar
      collapseOnSelect
      expand={navbarExpand}
      variant={navbarVariant}
      className={navbarClassName}
    >
      <div className={containerClassName}>
        <div className="d-flex align-items-center ">
          <Navbar.Brand onClick={logoClick} className="cursor">
            <img
              src={logoUrl}
              className={logoClassName}
              width={logoWidth}
              alt={logoAlt}
            />
          </Navbar.Brand>

          <ul className={breadcrumbClassName}>
            {breadcrumbs.map((crumb, index) => (
              <li key={index} className={crumb.isActive ? "active" : ""}>
                {crumb.label}
              </li>
            ))}
          </ul>
        </div>

        <div className={NavRightClassName}>
          <div className="headerSearch">
            <button
              style={{ backgroundColor: isProduction ? "#ffca15" : "#04AA6D" }}
            >
              {isProduction ? "Production" : "Test"}
            </button>
          </div>
          <div className="h-vr ml30 mr20"></div>
          <span className={SettingIconClass} onClick={onSettingsClick}></span>
          <div className={logoutClassName} onClick={onLogout}>
            Logout
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default Header;
