import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import styles from './accordion.module.scss';

interface DetailsProps {
  title: any;
  body: any;
}

interface AccordionProps {
  accordionArr: DetailsProps[];
}

function AccordionComponent({ accordionArr }: AccordionProps) {
  return (
    <Accordion>
      {accordionArr.map((item, index) => (
        <Accordion.Item key={index} eventKey={`${index}`}>
          <Accordion.Header>{item.title}</Accordion.Header>
          <Accordion.Body>{item.body}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default AccordionComponent;
